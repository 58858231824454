import {
  EXPERIMENT_ENABLE_POST_REACTIONS_CLIENT,
  SORT_BY_BEST_MATCH,
  SORT_BY_COMMENTS,
  SORT_BY_LAST_ACTIVITY,
  SORT_BY_LIKES,
  SORT_BY_MOST_VIEWS,
  SORT_BY_NEWEST,
  SORT_BY_REACTIONS,
} from '@wix/communities-forum-client-commons';
import { getPostSorting, getStyle } from './app-settings-selectors';
import { get } from 'lodash';
import { sortingToTranslations } from '../services/sorting-to-translations';
import { isExperimentEnabled } from './experiments-selectors';
import { getCategory } from '../../common/selectors/categories-selectors';

export const getCategoryPageSorting = (
  state: any,
  style = getStyle(state),
  categoryId?: string,
) => {
  const fallbackSort = SORT_BY_LAST_ACTIVITY;
  const isPostReactionsEnabled = isExperimentEnabled(
    state,
    EXPERIMENT_ENABLE_POST_REACTIONS_CLIENT,
  );

  const sort = transformSortValue(
    get(state, 'sorting.categoryPage', getPostSorting(style)),
    isPostReactionsEnabled,
  );

  if (!isPostReactionsEnabled) {
    return sort;
  }

  const category = getCategory(state, categoryId);
  if (category?.postInteraction === 'none' && sort === SORT_BY_REACTIONS) {
    return fallbackSort;
  }

  return sort;
};

export const getSearchPageSorting = (state: any) => {
  const isPostReactionsEnabled = isExperimentEnabled(
    state,
    EXPERIMENT_ENABLE_POST_REACTIONS_CLIENT,
  );

  const sort = transformSortValue(
    get(state, 'sorting.searchPage', SORT_BY_BEST_MATCH),
    isPostReactionsEnabled,
  );

  return sort;
};

export const getPostSortOptions = (state: any, categoryId: string, t: any) => {
  const isPostReactionsEnabled = isExperimentEnabled(
    state,
    EXPERIMENT_ENABLE_POST_REACTIONS_CLIENT,
  );
  const category = getCategory(state, categoryId);
  const showReactions = category?.postInteraction !== 'none';

  const reactionsSortOption = isPostReactionsEnabled
    ? showReactions
      ? { text: t(sortingToTranslations[SORT_BY_REACTIONS]), value: SORT_BY_REACTIONS }
      : null
    : { text: t(sortingToTranslations[SORT_BY_LIKES]), value: SORT_BY_LIKES };

  return [
    {
      text: t(sortingToTranslations[SORT_BY_LAST_ACTIVITY]),
      value: SORT_BY_LAST_ACTIVITY,
    },
    { text: t(sortingToTranslations[SORT_BY_NEWEST]), value: SORT_BY_NEWEST },
    { text: t(sortingToTranslations[SORT_BY_COMMENTS]), value: SORT_BY_COMMENTS },
    { text: t(sortingToTranslations[SORT_BY_MOST_VIEWS]), value: SORT_BY_MOST_VIEWS },
    ...(reactionsSortOption ? [reactionsSortOption] : []),
  ];
};

export const transformSortValue = (sort: any, isPostReactionsEnabled: boolean) => {
  if (isPostReactionsEnabled) {
    if (sort === SORT_BY_LIKES) {
      return SORT_BY_REACTIONS;
    }

    return sort;
  }

  if (sort === SORT_BY_REACTIONS) {
    return SORT_BY_LIKES;
  }

  return sort;
};
